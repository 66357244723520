<template>
    <div id="Verify" class="home-us">
        <div class="banner-con">
            <div class="top-left-con">
                <div class="line-one" v-visible="false"></div>
                <div class="line-two" v-visible="false"></div>
                <div class="line-three"></div>
                <p class="bg-tit2">
                    {{ $t("Verify.title") }}
                    <span>
                        <img
                            style="width: 90px"
                            src="../assets/img/2-Icons/icons_connect-wh.svg"
                            alt=""
                        />
                    </span>
                </p>
            </div>
            <div class="top-right-con"></div>
            <div class="bottom-left-con"></div>
        </div>
        <div class="content">
            <div class="title-text">
                    <span>
                        <img
                            class="connet"
                            src="../assets/img/2-Icons/icons_connect-left-or.svg"
                            alt=""
                        />
                    </span>
                    <span class="text-blue">
                        {{ $t("Verify.title3") }}
                    </span>
                    <span>
                        <img
                            class="connet"
                            src="../assets/img/2-Icons/icons_connect-right-or.svg"
                            alt=""
                        />
                    </span>
                </div>
            <div class="verify-content">
                <div class="verity" v-for="(item,index) in verityList" :key="index">
                    <div class="step">
                        <div class="row">
                            <div class="step-tit">
                            <div class="step-con">
                             <p class="step-title">{{$t("Verify.step")}}</p>
                             <span class="step-index">{{item.index}}</span>

                            </div>
                            <span class="step-txt">{{item.tit}}</span>
                        </div>
                        <div class="step-text">
                            <div v-if="item.index == 1">
                                <p>{{$t("Verify.step1_1")}}</p>
                                <br />
                                <p>{{$t("Verify.step1_2")}}</p>
                                <br />
                                <p>{{$t("Verify.step1_3")}}</p>
                                <br />
                            </div>
                            <div v-if="item.index == 2">
                            
                                <p class="bold" style="font-weight: bold">{{$t("Verify.step2_1")}}</p>
                                <br />
                                <p>{{$t("Verify.step2_2")}}</p>
                                <br />
                                <p class="bold" style="font-weight: bold">{{$t("Verify.step2_3")}}</p>
                                <br />
                             
                                <table border="1">
                                    <tr>
                                        <td width='120' class="td1">{{$t("Verify.step2_4")}}</td>
                                        <td class="td2">
                                            <p>{{$t("Verify.step2_5")}}</p>
                                            <p>{{$t("Verify.step2_6")}}</p>
                                            <p>{{$t("Verify.step2_7")}}</p>
                                            <br />
                                            <p>{{$t("Verify.step2_8")}}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td1">{{$t("Verify.step2_9")}}</td>
                                        <td class="td2">{{$t("Verify.step2_10")}}</td>
                                    </tr>
                                    <tr>
                                        <td class="td1">{{$t("Verify.step2_11")}}</td>
                                        <td class="td2">{{$t("Verify.step2_12")}}</td>
                                    </tr>
                                     <tr>
                                        <td class="td1">{{$t("Verify.step2_13")}}</td>
                                        <td class="td2" v-html="$t('Verify.step2_14')"></td>
                                    </tr>
                                </table>

                                <br />
                                <p class="bold" style="font-weight: bold">{{$t("Verify.step2_0")}}</p>
                                <br />

                                <table border="1">
                                    <tr>
                                        <td width='120' class="td1">{{$t("Verify.step2_4")}}</td>
                                        <td class="td2">
                                            <p>{{$t("Verify.step2_15")}}</p>
                                            <p>{{$t("Verify.step2_16")}}</p>
                                            <p>{{$t("Verify.step2_17")}}</p>
                                            <br />
                                            <p>{{$t("Verify.step2_18")}}</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="td1">{{$t("Verify.step2_19")}}</td>
                                        <td class="td2">{{$t("Verify.step2_20")}}</td>
                                    </tr>
                                    <tr>
                                        <td class="td1">{{$t("Verify.step2_21")}}</td>
                                        <td class="td2">{{$t("Verify.step2_22")}}</td>
                                    </tr>
                                    
                                    
                                </table>
                            </div>
                            <div v-if="item.index == 3">
                                <p>{{$t("Verify.step3_1")}}</p>
                                <br />
                                <p>{{$t("Verify.step3_2")}}</p>
                                <br />
                                <p  style="text-align: center;" v-html='$t("Verify.link2")'></p>
                                <br />
                                <p style="text-align: center;" v-html='$t("Verify.link3")'></p>
                            </div>
                            <div v-if="item.index == 4">
                                <p>{{$t("Verify.step4_1")}}</p>
                                <br />
                                <p>{{$t("Verify.step4_2")}}</p>
                                <p>{{$t("Verify.step4_3")}}</p>
                                <p>{{$t("Verify.step4_4")}}</p>
                                <br />
                                <p>{{$t("Verify.step4_5")}}</p>
                            </div>
                            <div v-if="item.index == 5">
                                <p>{{$t("Verify.step5_1")}}</p>
                                <br />
                                <p>{{$t("Verify.step5_2")}}</p>
                            </div>
                                      
                        </div>
                        
                        </div>
                        <div  class="title-con" v-if="item.index == 1">
                         <p>{{$t("Verify.step1_4")}}</p>
                        </div>
                        <div class="link-container">
                        <div class="list"  v-if="item.index == 1">
                         <p class="one" v-for="(item,index) in linkList" :key="index" v-html="$t('Verify.' + item.link)"></p>
                        </div>
                        <div class="list2"  v-if="item.index == 1">
                        <p class="two" v-for="(item,index) in linkList2" :key="index" v-html="$t('Verify.' + item.link)"></p>
                        </div>
                        </div>
                       
                    </div>
                    
                    <!-- <div class="step-icon">
                        <img src="../assets/img/2-Icons/icons_verify-coach.svg" alt="" class="icon-img">
                    </div> -->
                </div>
            </div>
            <div class="verity">
                <div class="step">
                     <div class="last">
                    <p class="last_1">{{$t("Verify.step6_1")}}</p>
                    <br />
                    <p>{{$t("Verify.step6_2")}}</p>
                    <br />

                    <table  border="1">
                                    <tr style="font-weight: bold" width='150'>
                                        <td class="td2">{{$t("Verify.last1")}}</td>
                                        <td class="td2">
                                            <p>{{$t("Verify.last2")}}</p>
                                            
                                        </td>
                                        <td class="td2">{{$t("Verify.last3")}}</td>
                                    </tr>
                                    <tr>
                                        <td class="td2" width='150'>{{$t("Verify.last4")}}</td>
                                        <td class="td2" width='160'>{{$t("Verify.last5")}}</td>
                                        <td class="td2">{{$t("Verify.last6")}}</td>

                                    </tr>
                                    <tr>
                                        <td class="td2" width='150'>{{$t("Verify.last7")}}</td>
                                        <td class="td2" width='160'>{{$t("Verify.last8")}}</td>
                                        <td class="td2">{{$t("Verify.last9")}}</td>

                                    </tr>
                                    <tr>
                                        <td class="td2"  width='150'>{{$t("Verify.last10")}}</td>
                                        <td class="td2" width='160'>{{$t("Verify.last11")}}</td>
                                        <td class="td2">{{$t("Verify.last12")}}</td>

                                    </tr>
                                    
                                </table>
                    <br />

                    <p>{{$t("Verify.step6_3")}}</p>

                    </div>
                </div>
                </div> 
        </div>
    </div>
</template>
<script>
export default {
    name: "Verify",
    data() {
        return {
            linkList: [
                {link: 'link_1'},
                {link: 'link_2'},
                {link: 'link_3'},
                {link: 'link_4'},
                {link: 'link_5'},
                {link: 'link_6'},
                {link: 'link_7'},
                {link: 'link_8'},
                {link: 'link_9'},
                {link: 'link_10'},
                {link: 'link_11'},
                {link: 'link_12'},
                {link: 'link_13'},
                {link: 'link_14'},
                {link: 'link_15'},
                {link: 'link_16'},
                {link: 'link_17'},
                {link: 'link_18'},
                {link: 'link_19'},
                {link: 'link_20'},
                {link: 'link_21'},
                {link: 'link_22'},
                {link: 'link_23'},
                {link: 'link_24'},
                {link: 'link_25'},
            ],
            linkList2: [
                {link: 'link_26'},
                {link: 'link_27'},
                {link: 'link_28'},
                {link: 'link_29'},
                {link: 'link_30'},
                {link: 'link_31'},
                {link: 'link_32'},
                {link: 'link_33'},
                {link: 'link_34'},
                {link: 'link_35'},
                {link: 'link_36'},
                {link: 'link_37'},
                {link: 'link_38'},
                {link: 'link_39'},
                {link: 'link_40'},
                {link: 'link_41'},
                {link: 'link_42'},
                {link: 'link_43'},
                {link: 'link_44'},
                {link: 'link_45'},
                {link: 'link_46'},
                {link: 'link_47'},
                {link: 'link_48'},
                {link: 'link_49'},
                {link: 'link_50'},
            ],
            verityList:[
                {
                    index:1,
                    icon:'',
                    tit:this.$t("Verify.step1"),
                    cnt:'and ask the coach to tell you about their previous experiences. '
                    
                },
                {
                    index:2,
                    icon:'',
                    tit:this.$t("Verify.step2"),
                    cnt:'and ask the coach to tell you about their previous experiences. '
                },
                {
                    index:3,
                    icon:'',
                    tit:this.$t("Verify.step3"),
                    cnt:'and ask the coach to tell you about their previous experiences. '
                },
                {
                    index:4,
                    icon:'',
                    tit:this.$t("Verify.step4"),
                    cnt:'and ask the coach to tell you about their previous experiences. '
                },
                {
                    index:5,
                    icon:'',
                    tit:this.$t("Verify.step5"),
                    cnt:'and ask the coach to tell you about their previous experiences. '
                },
                
                
            ],
        }
    }
}
</script>
<style lang="scss" scoped>
.banner-con {
        position: relative;
        height: 500px;
        .top-left-con {
            width: 92%;
            height: 500px;
            background: #28327b;
            position: absolute;
            top: 0;
            left: 0;
            transform: skew(-15deg) translateX(-9%);
            z-index: 2;
            .line-one {
                width: 338px;
                height: 2px;
                background: #fff;
                position: absolute;
                top: 24%;
                right: 2%;
            }
            .line-two {
                width: 120px;
                height: 2px;
                background: #fff;
                position: absolute;
                top: 42%;
                right: 2%;
            }
            .banner-tit1 {
                width: auto;
                margin-top: 18%;
                margin-left: 2%;
                padding-left: 13%;
                text-align: left;
                font-size: 70px !important;
                color: #fff;
                transform: skew(6deg);
                font-weight: bold;
            }
            .banner-tit2 {
                width: auto;
                margin-top: -2%;
                margin-left: 4%;
                padding-left: 10%;
                text-align: left;
                font-size: 70px !important;
                color: #fff;
                transform: skew(6deg);
                font-weight: bold;
                // font-family: "NotoSansCJKtc-Black";
            }
            .bg-tit1 {
                margin-top: 150px;
                height: 36px;
                font-size: 32px !important;
                font-weight: 400;
                line-height: 42px;
                color: #ffffff;
                transform: skew(13deg);
            }
            .bg-tit2 {
                margin: 0 auto;
                margin-top: 180px;
                font-size: 75px;
                font-weight: bold;
                line-height: 75px;
                color: #ffffff;
                font-size: 70px !important;
                color: #fff;
                opacity: 1;

                transform: skew(13deg);
            }
            .more {
                position: absolute;
                bottom: 20px;
                right: 20px;
                color: #fff;
                transform: skew(15deg);
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .top-right-con {
            width: 21%;
            height: 500px;
            background: #f15922;
            position: absolute;
            right: -5%;
            transform: skew(-15deg) translateX(2.5%);
        }
        .bottom-left-con {
            width: 40%;
            height: 95px;
            background: #f15922;
            position: absolute;
            bottom: -8%;
            left: 0;
            z-index: 2;
            transform: skew(-15deg) translateX(-3%);
        }
    }
.content{
    background: url("../assets/img/bg-blue.jpg");
    margin: 0 auto;
    padding-top: 100px;
    padding-bottom: 135px;
    .verity{
        position: relative;
        margin-bottom: 100px;
        display: flex;
        align-items: center;
    }
    .step{
        display: flex;
        flex-direction: column;
        width: 1300px;
        // height: 159px;
        background: #FFFFFF;
        box-shadow: 0px 0px 20px #D1D4EB;
        border-radius: 8px;
        margin: 0 auto;
        text-align:left;
        align-items: center;
        .last{
            padding: 50px;
            .last_1{
                font-weight: bold;
                text-align: center;
                font-size: 25px;
            }
        }
        .row{
            display: flex;
            align-content: center;
            flex-direction: row;
            align-items: center;
            padding: 70px 70px 70px 30px;
        }
        .title-con{
            font-weight: bold;
        }
        .link-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            .list {
                display: flex;
                flex-direction: column;
                padding: 50px;

            }
            .list2 {
                display: flex;
                flex-direction: column;
                padding: 50px;

            }
        }
        
        .step-tit{
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 60%;
            margin-right: 10px;
            .step-con{
                display: flex;
            }
            .step-txt{
                margin-left:10px;
                height: 22px;
                font-size: 22px;
                font-weight: bold;
                line-height: 28px;
                color: #F15922;
            }
            .step-title{
                margin-right: 10px;
                color: #F15922;
                font-size: 22px;
                font-weight: bold; 
                margin-top: -4px;
           
            }
            .step-index{
                margin-bottom: 10px;
                display: inline-block;
                width: 25px;
                height: 25px;
                background: #F15922;
                border-radius: 50%;
                font-size: 18px;
                font-weight: bold;
                line-height: 25px;
                text-align:center;
                color: #FFFFFF;
            }
        }
        .step-text{
            width: 100%;
        }
        .bold{
            font-size: 20px;
        }
         
    }
    .step-icon{
        position: absolute;
        width: 196px;
        height: 196px;
        background: #FBF7F6;
        border: 3px solid #FFE4DB;
        border-radius: 50%;
        left: 10%;
        .icon-img{
            width:75px;
            height:75px;
            position: absolute;
            left: 30%;
            top: 30%;
        }
    }
    .title-text {
    font-size: 30px;
    margin-bottom: 100px;
    }
    .text-blue {
        color: #28327b;
        font-weight: bold;
    }
    img.connet {
        width: 50px;
        margin: 0 10px;
    }
    .td1{
        padding-left: 10px;
    }
    .td2{
        padding: 10px;
    }
    .pad{
        padding: 55px;
    }
    
}
</style>
<style lang="scss">
@media screen and (max-width: 480px) {
    #Verify{
        .step{
            padding-left: 3rem;
        }
        .step-icon{
            left: 0;
            top:0;
        }
        .verity{
            overflow: auto;
        }
        .bg-tit1 {
                font-size: 35px !important;
                margin-top: 15% !important;
                padding-left: 30%;
        }
        .bg-tit2 {
            margin-top: 2% !important;
            padding-left: 30% !important;
            font-size: 35px !important;
            padding-left: 0;
        }
    }
}
</style>